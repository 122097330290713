import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from "@angular/forms";
import { FormComponentsModule } from "../form-components.module";

@Component({
  selector: "lib-input-editor",
  standalone: true,
  imports: [CommonModule, FormComponentsModule, ReactiveFormsModule],
  templateUrl: "./input-editor.component.html",
  styleUrls: ["./input-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEditorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditorComponent implements OnInit {
  @Input() validators = [];
  @Input() isRequired = false;
  @Input() label = "";

  isDisabled = false;
  value = "";

  form = new FormGroup({
    currentUrl: new FormControl(""),
    savedUrl: new FormControl(""),
    isEditing: new FormControl(false)
  });

  get currentUrlControl(): FormControl {
    return this.form.controls.currentUrl;
  }

  get savedUrlControl(): FormControl {
    return this.form.controls.savedUrl;
  }

  get isEditingControl(): FormControl {
    return this.form.controls.isEditing;
  }

  ngOnInit(): void {
    this.currentUrlControl.setValidators(this.validators);
    this.form.updateValueAndValidity();
  }

  onEdit(): void {
    this.isEditingControl.setValue(true, { emitEvent: true });
    this.currentUrlControl.enable();
  }

  onSave(): void {
    this.isEditingControl.setValue(false, { emitEvent: true });
    this.savedUrlControl.setValue(this.currentUrlControl.value);
    this.currentUrlControl.disable();
    this.valueChanged();
  }

  writeValue(value: string): void {
    const savedUrl = value;
    const currentUrl = savedUrl ?? "";

    this.form.patchValue(
      {
        currentUrl,
        savedUrl,
        isEditing: false
      },
      { emitEvent: false }
    );

    if (savedUrl) {
      this.currentUrlControl.disable();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  valueChanged(): void {
    const { savedUrl } = this.form.getRawValue();

    this.onChange(savedUrl || "");
  }

  private onChange = (value: string) => {};

  private onTouched = () => {};
}
